<template>
	<div id="user-center">
		<v-head :title="title"></v-head>
		<router-view></router-view>
	</div>
</template>

<script>
	import vHead from '@/layouts/MobileHead.vue'
	export default {
		components: {
			vHead,
		},
		mounted: function(){
			this.pathName = this.$route.path
			if(! this.$store.getters.userInfo) {
				this.$router.push('/login')
			} else {
				this.title = this.paths[this.pathName]
				if(this.pathName.indexOf('/user/booking/record/detail/') != -1) {
					this.title = '病歷詳情'
				}
				this.title = this.title || '個人中心'
			}
		},
		data() {
			return {
				title: '個人中心',
				pathName: '',
				paths: {
					'/user/booking/order': '預約訂單',
					'/user/booking/record': '歷史病歷',
					'/user/association/myActivity': '學會活動',
					'/user/aq-news/question': '我的問答',
					'/user/userInfo': '用戶信息维护',
					'/user/updatePwd': '修改密碼',
					'/user/card/plan': '醫療計劃',
					'/user/card/service': '服務卡',
					'/user/resume': '我的履歷',
					'/user/applyJobs': '我的職位申請',
					'/user/aq-news/news': '我的資訊',
					'/user/aq-news/addNews': '發佈資訊',
				}
			}
		},
		watch: {
			$route(to,from){
				this.pathName = to.path
				this.title = this.paths[to.path] || '個人中心'
			}
		}
	};
</script>

<style lang="scss" scoped>
	#user-center {
		width: 100%;
		padding-top: 83px;
		min-height: 100%;
		display: flex;
		flex-direction: column;
	}

	.user-avatar-box {
		width: 100%;
		height: 210px;
		background-image: url(../../../assets/imgs/pc/user/img_banner@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.user-avatar-box > .user-avatar {
		width: 1000px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 210px;
		margin: 0px auto;
	}

	.user-avatar-box > .user-avatar > .img-avatar {
		width: 108px;
		height: 108px;
		border-radius: 50%;
	}

	.user-avatar-box > .user-avatar > .user-name {
		font-weight: 20px;
		font-weight: 400;
		margin-top: 19px;
		color: #fff;
	}

	.user-tabs-w {
		width: 100%;
		height: 94px;
		background-color: #fff;
		margin-bottom: 10px;
	}

	.user-tabs {
		display: flex;
		flex-direction: row;
		width: 1000px;
		height: 94px;
		margin: 0 auto;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.user-tabs .user-tab {
		display: block;
		width: 133px;
	}

	.user-tabs .user-tab:nth-child(1) {
		margin-left: 200px;
	}

	.user-tabs .user-tab > .user-tab-v {
		display: inline-block;
		color: #969696;
		font-size: 16px;
		font-weight: 400;
		height: 15px;
		line-height: 15px;
		position: relative;
		padding-bottom: 20px;
		margin-top: 40px;
	}

	.user-tabs .user-tab > .user-tab-v.current {
		position: relative;
		font-weight: bold;
		color: #231F20;
	}

	.user-tabs .user-tab > .user-tab-v.current::after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -16px;
		width: 20px;
		height: 4px;
		background-color: #231F20;
	}
</style>
